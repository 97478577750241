import axios from './axios';
import { isArray } from '../helpers/javascript';
import { api } from './app.config';
import { getAuthHeader } from './security.service';
export var find = function find() {
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/notifications"),
      method: 'GET',
      headers: getAuthHeader()
    }).then(function (response) {
      if (isArray(response.data)) resolve(response.data);else reject();
    })["catch"](function () {
      reject();
    });
  });
};
export var remove = function remove(_ref) {
  var _id = _ref._id;
  return new Promise(function (resolve, reject) {
    axios({
      url: "".concat(api, "/notifications/").concat(_id),
      method: 'DELETE',
      headers: getAuthHeader()
    }).then(function (response) {
      resolve(response.data);
    })["catch"](function () {
      reject();
    });
  });
};
export var isFirstTimeLoad = function isFirstTimeLoad(key) {
  var value = localStorage.getItem(key || 'woxo_tutorial_load');
  if (value) return false;
  return true;
};
export var setFirstTimeLoad = function setFirstTimeLoad(key) {
  localStorage.setItem(key || 'woxo_tutorial_load', 'true');
};